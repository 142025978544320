import React from 'react'
import { useEffect, useState } from 'react'
import styled from 'styled-components'

import { Wrapper } from './styles'

const BannerText = styled.span`
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: #fff;
  text-align: center;
  text-transform: none;
  & a {
    text-decoration: underline;
  }
`

export const Banner = (): JSX.Element => {
  const [timer, setTimer] = useState('')
  const formatTime = (time: number) => {
    const days = Math.floor(time / 60 / 60 / 24)
    const hours = Math.floor((time - days * 60 * 60 * 24) / 60 / 60)
    const minutes = Math.floor((time - days * 60 * 60 * 24 - hours * 60 * 60) / 60)
    const seconds = Math.floor(time - days * 60 * 60 * 24 - hours * 60 * 60 - minutes * 60)

    const formattedHours = String(hours).padStart(2, '0')
    const formattedMinutes = String(minutes).padStart(2, '0')
    const formattedSeconds = String(seconds).padStart(2, '0')

    const daysString = days > 0 ? `${days} days ` : ''

    return `${daysString} ${formattedHours} hours ${formattedMinutes} minutes ${formattedSeconds} seconds`
  }

  useEffect(() => {
    setInterval(() => {
      const sec = Math.floor((1729893600000 - new Date().getTime()) / 1000) - 1

      setTimer(sec > 0 ? formatTime(sec) : '')
    }, 1000)
  }, [])
  return (
    <Wrapper>
      <BannerText>🔥 JAMTON mainnet launch in {timer}!</BannerText>
    </Wrapper>
  )
}

const BannerWrapper = styled.div`
  width: 100%;
  background-color: var(--blue);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 9px 16px;
`

const BannerLink = styled.a`
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  color: #fff;
  text-transform: none;
`

export const Announcement = (): JSX.Element => (
  <div style={{ background: 'var(--blue)', color: 'var(--white)', flexDirection: 'column' }}>
    <BannerWrapper>
      <BannerLink href={'https://t.me/curiocarQA'} target="_blank">
        � Capital DEX and RollApp dApps are undergoing maintenance 🚨
      </BannerLink>
    </BannerWrapper>
    <BannerWrapper>
      <BannerLink href={'https://t.me/curiocarQA'} target="_blank">
        Wait for the announcement in our Telegram Channel
      </BannerLink>
    </BannerWrapper>
  </div>
)
